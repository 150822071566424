import React from 'react'

function GreenDot () {
	return (
		<span className='green-dot'>
			.
		</span>
	)
}

GreenDot.defaultProps = {}

GreenDot.propTypes = {}

export default GreenDot;