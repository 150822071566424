import SlideUp from './slide-up'
import Word from './word'
import Fade from './fade';

const Transitions = {
	SlideUp,
	Word,
	Fade
}

export default Transitions;