import React from 'react'
import PropTypes from 'prop-types'

function SlideUp ({ children, duration, delay }) {
	return (
		<div
			style={{
				'--sal-duration': `${duration}ms`,
				'--sal-delay': `${delay}ms;`
			}}
			data-sal="slide-up"
			data-sal-easing="ease"
		>
			{children}
		</div>
	)
}

SlideUp.defaultProps = {
  children: <></>,
  duration: '350',
  delay: '0',
}

SlideUp.propTypes = {
  children: PropTypes.node.isRequired,
  duration: PropTypes.string,
  delay: PropTypes.string,
}

export default SlideUp;