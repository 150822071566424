import React from 'react'
import PropTypes from 'prop-types'

function Fade ({ children, duration, delay, ...rest }) {
	return (
		<span
			// style={`--sal-duration: ${duration}ms; --sal-delay: ${delay}ms;`}
			style={{
				'--sal-duration': `${duration}ms`,
				'--sal-delay': `${delay}ms;`
			}}
			data-sal='fade'
			data-sal-easing='ease'
			{...rest}
		>
			{children}
		</span>
	)
}

Fade.defaultProps = {
	duration: '200',
	delay: '0',
}

Fade.propTypes = {
  children: PropTypes.node.isRequired,
  duration: PropTypes.string,
  delay: PropTypes.string,
}

export default Fade;