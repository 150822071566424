import React from 'react'
import PropTypes from 'prop-types'
import Fade from './fade';

function Word () {
	return (
		<h1>
			<span>
				<Fade delay='500'>
					we {' '}
				</Fade>
				<Fade delay='1000'>
					create {' '}
				</Fade>
			</span>
			<span>
				<Fade delay='1500' duration='300'>
					extraordinary {' '}
				</Fade>
				<Fade delay='1500' duration='300'>
					websites
				</Fade>
				<Fade delay='2200' className='green-dot'>
					.
				</Fade>
			</span>
		</h1>
	)
}

Word.defaultProps = {}

Word.propTypes = {
  str: PropTypes.string.isRequired,
}

export default Word;