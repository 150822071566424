import React from "react"
import { RichText } from "prismic-reactjs"
import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from 'gatsby'
import Transitions from '../components/transitions'
import GreenDot from '../components/green-dot'

const Successes = ({ content, posts }) => {
  if (!content) return null
  return (
    <>
      <SEO 
        image={posts.edges[0].node.cover_image.url+"&w=1200"}
        title={RichText.asText(content.seo_title)}
        keywords={RichText.asText(content.seo_keywords)}
        description={RichText.asText(content.seo_description)}
        url="https://bonsai.is/blog"
      />
      <div className="full_banner d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-11">
              <Transitions.SlideUp>
                <h1>{RichText.asText(content.title)}<GreenDot/></h1>
              </Transitions.SlideUp>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-list">
        <div className="container">
          {posts.edges.map((post) => {
            return(
              <div className="blog-post">
                <div className="post-image position-relative">
                  <Link to={"/blog/"+post.node._meta.uid}>
                    <Img fluid={post.node.cover_imageSharp.childImageSharp.fluid} alt={post.node.cover_image.alt}/>
                  </Link>
                  <Transitions.SlideUp>
                    <p className="mb-0 post-info post-read">{RichText.asText(post.node.read)}</p>
                  </Transitions.SlideUp>
                  <Transitions.SlideUp>
                    <p className="mb-0 post-info post-duration">{Intl.DateTimeFormat('en-US', { month: 'long', day:'numeric', year: 'numeric' }).format(new Date(post.node.date))}</p>
                  </Transitions.SlideUp>
                </div>
                <div className="post-title">
                  <h2><Link to={"/blog/"+post.node._meta.uid}>{RichText.asText(post.node.title)}</Link></h2>
                </div>
            </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ({ data }) => {
  const content = data.prismic.allBlogs.edges[0].node
  const posts = data.prismic.allPosts
  if (!content) return null
  return (
    <Layout>
      <Successes content={content} posts={posts}/>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allBlogs {
        edges {
          node {
            seo_description
            seo_keywords
            seo_title
            title
          }
        }
      }
      allPosts(sortBy: date_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            cover_image
            date
            read
            title
            cover_imageSharp {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`